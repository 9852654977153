import React from "react";
import { Link } from "react-router-dom";
import firebase, { UserDB } from "Firebase.js"

const udb = firebase.database().ref("/users");
export default class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
  componentDidMount() {
	const parent = this
	firebase
		.database()
		.ref("forms")
		.once("value")
		.then(function (snapshot) {
			console.log(snapshot.val())
			parent.setState({val: snapshot.val()});
			console.log(parent.state)
		});
  }
	
  handleSubmit = (e) => {
	  e.preventDefault()
	  firebase
	    .database()
			.ref("forms")
			.push(e.target.class.value)
			.then(() => {
				this.componentDidMount();
			}).catch((error) => {
				alert("Error! Please send this to Ethan, Otto, or Clint on Slack: formadmin.js in function handleSubmit "+error)
			});
  }
  
	handleRequestClick = (e, c) => {
		e.stopPropagation()
		const parent = this
		console.log(c)
		firebase
			.database()
			.ref("/forms")
			.child(c)
			.remove()
			.then(() => {
				parent.componentDidMount();
			}).catch((error) => {
				alert("Error ! Please send this to Ethan, Otto, or Clint on Slack: formadmin.js in function handleRequestClick "+error)
			});
  }
	
  classTable = () => {
	  var classes = []
	  for(const c in this.state.val){
		  classes.push(
		  <tr className="px-4 py-2 border-b">
			  <td className="px-4 py-2 border-b mt-1 text-sm leading-5 text-gray-500">
			     {this.state.val[c]}
			  </td>
				<td className="px-4 py-2 border-b">
					<button className="ml-2 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-3 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
						Edit
					</button>
				</td>
			  <td className="flex justify-end px-4 py-2">
					<svg onClick={(event) => this.handleRequestClick(event, c)} class="h-5 w-5 fill-current text-red-600 hover:text-red-700" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
					</svg>
			  </td>
		  </tr>
		  )
	  }
	  classes.push(
		  <tr className="px-4 py-2 border-b bg-gray-50">
			  <td className="px-4 py-2 border-b mt-1 text-sm leading-5 text-gray-500">
			      <input name="class" id="class" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
				  </input>
			  </td>
				<td>
				</td>
			  <td className="px-4 py-2 border-b mt-1 text-sm leading-5 text-gray-500 flex justify-end">
			      <input type="submit" value="Add" className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
				  </input>
			  </td>
		  </tr>
	  )
	  return(classes)
  }
  
  render() {
    return (
      <form onSubmit={this.handleSubmit}>
			<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
						<h3 className="text-lg font-medium leading-6 text-gray-900">
							Forms
						</h3>
						<p class="mt-1 text-sm leading-5 text-gray-500">
							Admin portal for creating NHS forms. 
						</p>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
							<table className="table-fixed min-w-full">
								<tr className="bg-gray-50 border-b">
									<th className="mt-1 text-sm leading-5 font-medium text-gray-500 flex justify-left px-4 py-2 w-5/6">
										Forms
									</th>
									<th className="w-1/12">
									</th>
									<th className="w-1/12">
									</th>
								</tr>
								<this.classTable />
							</table>
						</div>
					</div>
				</div>
			</div>
		</form>
  );
  }
}