import firebase from "firebase";
let config = {
    apiKey: "AIzaSyCInDSiCzj1cQWB7h1cfisxLW2nyLZNXqI",
    authDomain: "soar-portal.firebaseapp.com",
    databaseURL: "https://soar-portal-default-rtdb.firebaseio.com",
    // projectId: "gdhq-portal",
    // storageBucket: "gdhq-portal.appspot.com",
    // messagingSenderId: "XXXX"
};

firebase.initializeApp(config);

const googleProvider = new firebase.auth.GoogleAuthProvider()
const udb = firebase.database().ref("/users");
const sdb = firebase.database().ref("/sessions");
const pdb = firebase.database().ref("/projects");

class User {
  getAll() {
    return udb;
  }

  create(user) {
    return udb.push(user);
  }

  update(key, value) {
    return udb.child(key).update(value);
  }

  delete(key) {
    return udb.child(key).remove();
  }

  deleteAll() {
    return udb.remove();
  }
  
  getUser(id) {
	 var list = []
	 udb.orderByChild("id").equalTo(id).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push(snapshot.key);
	  });
	  return list;
  }
  getContents(id) {
	 var list = []
	 udb.orderByChild("id").equalTo(id).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push(snapshot.exportVal());
	  });
	  return list;
  }
}

class Sessions {
  getAll() {
    return sdb;
  }

  create(user) {
    return sdb.push(user);
  }

  update(key, value) {
    return sdb.child(key).update(value);
  }

  delete(key) {
    return sdb.child(key).remove();
  }

  deleteAll() {
    return sdb.remove();
  }
  
  getSess(id, attr) {
	 var list = []
	 sdb.orderByChild(attr).equalTo(id).on("child_added", function(snapshot) {
		  console.log(snapshot.ref.toString().substring(snapshot.ref.root.toString().length-1))
		  list.push(snapshot.key);
	  });
	  return list;
  }
  getContents(id, attr) {
	 var list = []
	 sdb.orderByChild(attr).equalTo(id).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push(snapshot.exportVal());
	  });
	  return list;
  }
  getFromRef(id) {
	console.log(id)
	var list = []
	if (id !== "") {
		 sdb.orderByKey().equalTo(id).on("child_added", function(snapshot) {
			var key = snapshot.key;
			console.log(snapshot.exportVal())
			list.push(snapshot.exportVal())
		  });
	}
	return (list);
  }
}

class Projects {
  getAll() {
    return pdb;
  }

  create(user) {
    return pdb.push(user);
  }

  update(key, value) {
    return pdb.child(key).update(value);
  }

  delete(key) {
    return pdb.child(key).remove();
  }

  deleteAll() {
    return pdb.remove();
  }
  
  getProject(id, attr) {
	 var list = []
	 pdb.orderByChild(attr).equalTo(id).on("child_added", function(snapshot) {
		  console.log(snapshot.ref.toString().substring(snapshot.ref.root.toString().length-1))
		  list.push([snapshot.key, snapshot.exportVal()]);
	  });
	  return list;
  }
  getContents(id, attr) {
	 var list = []
	 pdb.orderByChild(attr).equalTo(id).on("child_added", function(snapshot) {
		  console.log("Duplicate: " + snapshot.key)
		  list.push(snapshot.exportVal());
	  });
	  return list;
  }
  getFromRef(id) {
	console.log(id)
	var list = []
	if (id !== "") {
		 pdb.orderByKey().equalTo(id).on("child_added", function(snapshot) {
			var key = snapshot.key;
			console.log(snapshot.exportVal())
			list.push(snapshot.exportVal())
		  });
	}
	return (list);
  }
}

export default firebase

export const signInWithGoogle = () => {
  firebase.auth().signInWithPopup(googleProvider).then((res) => {
    console.log("User data: " + res.user)
  }).catch((error) => {
    console.log(error.message)
  })
}

export const ProjDB = new Projects();
export const UserDB = new User();
export const SessDB = new Sessions();
