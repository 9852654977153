import React, { Component, useEffect } from "react";
import YouTube from 'react-youtube'; //npm install react-youtube
import firebase from "firebase";
let config = {
    apiKey: "",
    authDomain: "",
    databaseURL: ""
};
//Uncomment these if firebase is not already initialized
//firebase.initializeApp(config);

//const googleProvider = new firebase.auth.GoogleAuthProvider()
const udb = firebase.database().ref("/users");
// components
//setup tab switch detection system 
let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}
export default class Videoplayer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {done: false};
	}
	componentDidMount() {
	  firebase.auth().onAuthStateChanged(user => {
		window.user = user
		console.log("WINDOW: "+window.user.email);
	  });

	}
	update(key, value) { //updates the user
		return udb.child(key).update(value);
	}
	getUser = (id) => { //gets the id of the user given their email, which is a global variable
	 
	  return;
	}
	  render() {
		  const opts = {
		  width: '972',
          height: '620',
          playerVars: {
            controls:0, //disables the skip time bar
			disablekb:1 //disables keyboard controls, idk if this works on other browsers
        },
      }; //the video in videoId is a test
		  return (
			<>
			  <div className="flex flex-wrap">
				  <div className="w-full px-4 flex justify-center">
					<YouTube videoId="FSICOM0sru4" opts={opts} onReady={this._onReady} onStateChange={(e) => {this.onPlayerStateChange(e);}}/>
				  </div>
			  </div>
			</>
		  );
	}
	_onReady = (event) => {
    // access to player in all event handlers via event.target
	event.target.pauseVideo();
	const tar = event.target;
	document.addEventListener(visibilityChange, () => this.tabSwitch(tar));
  }
  onPlayerStateChange = (event) => { //adds done attribute to user when video is done       
    if(event.data === 0) {
	  udb.orderByChild("id").equalTo(window.user.email).once("child_added")
	 .then((dataSnapshot) => {
		 console.log(dataSnapshot.key);
		const id = dataSnapshot.key;
		  console.log(id);
		  let data = {done: true};
		  this.update(id, data).then(() => {
				  console.log("Item " + id + "was updated with " + data + "successfully!")
			  }).catch((error) => {
				  console.log(error);
			  });
	  });
	}
  }
  //pauses when switches tab, plays when returns to tab. 
  tabSwitch(tar){
	  if(document[hidden]){
			tar.pauseVideo();
	  }
	  else {
		  tar.playVideo();
	  }
  }
}