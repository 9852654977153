import React, { Component, useEffect } from "react";
import firebase, { UserDB } from "Firebase.js"

// components
const udb = firebase.database().ref("/users");
export default class Tutor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {readOnly: false, submit: "Submit"};
	}
	
  handleSubmit = (event) => {
	  event.preventDefault()
	  if (this.state.submit == "Submit") {
		  let data = {
			  first: event.target.first_name.value,
			  last: event.target.last_name.value,
			  email: event.target.email.value,
			  link: event.target.link.value,
			  tutor: event.target.tutor.checked,
			  id: window.user.email
		  };
		  console.log(data);
		  if (data.first == ""){
			  alert("Please register on the Profile Page first!")
		  }
		  else {
		  if (this.state.id == undefined) {
			  UserDB.create(data).then(() => {
				  console.log("Created User with items" + data + "successfully!");
			  }).catch((error) => {
				  console.log(error);
			  });
		  }
		  else {
			  UserDB.update(this.state.id, data).then(() => {
				  console.log("Item " + this.state.id + "was updated with " + data + "successfully!")
			  }).catch((error) => {
				  console.log(error);
			  });
		  }
		  //window.location.reload();
		  this.componentDidMount();
		  }
	  }
	  else {
		  this.setState({readOnly: false, submit: "Submit"});
	  }
  }
  componentDidMount() {
	  firebase.auth().onAuthStateChanged(user => {
	  window.user = user
	  const id = window.user.email
	  udb.orderByChild("id").equalTo(id).once("child_added")
	  .then((dataSnapshot) => {
		const udata = dataSnapshot.key
		console.log(udata);
		this.setState({readOnly: udata !== undefined});
		const ucontents = dataSnapshot.exportVal()
		console.log(ucontents);
		if (ucontents !== undefined && ucontents.tutor == true) {
			this.setState({id: udata, first: ucontents.first, last: ucontents.last, email: ucontents.email, link: ucontents.link, tutor: ucontents.tutor, submit: "Edit"})
		}
		if (ucontents.tutor !== true) {
			this.setState({id: udata, first: ucontents.first, last: ucontents.last, email: ucontents.email, readOnly: false, submit: "Submit"});
	  }
	  })
	  //const udata = UserDB.getUser(window.user.email)
	  //console.log(udata);
	  //console.log(udata.length);
	  //console.log(udata.length !== 0);
	  //console.log(udata[0]);
	  /*this.setState({readOnly: udata[0] !== undefined});
	  const ucontents = UserDB.getContents(window.user.email)[0]
	  console.log(ucontents);
	  if (ucontents !== undefined && ucontents.tutor == true) {
		this.setState({first: ucontents.first, last: ucontents.last, email: ucontents.email, link: ucontents.link, tutor: ucontents.tutor, submit: "Edit"})
	  }
	  if (ucontents.tutor == false) {
		  this.setState({readOnly: false, submit: "Submit"});
	  }
	  */
    })
  }
  render() {
	  return (
		<>
		  <div className="flex flex-wrap">
			  <div className="w-full px-4">
					<div className="w-full mx-auto bg-blue-600 rounded-xl shadow-md overflow-hidden flex justify-center md:pt-2 px-4">
						<div class="p-2">
							<div class="tracking-wide text-sm text-yellow-300 text-5xl font-thin text-center pb-4">
								For Tutors
							</div>
						<p class="text-white font-thin text-center">
							Review your information, answer the following questions to apply for a tutoring position, and agree to the following statements to apply for a tutoring position. (If the boxes are blank, please make sure to register as a member in the profile section.)
						</p>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-3 md:gap-6">
							<div className="md:col-span-1">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">If you have already registered as a member of SOAR, confirm your personal information. Otherwise, please register on the Profile page first.</p>
							</div>
							<div className="mt-5 md:mt-0 md:col-span-2">
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label for="first_name" className="block text-sm font-medium leading-5 text-gray-700">First Name</label>
										<input defaultValue={this.state.first} disabled="true" required id="first_name" name="first_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-3">
										<label for="last_name" className="block text-sm font-medium leading-5 text-gray-700">Last Name</label>
										<input defaultValue={this.state.last} disabled="true" required id="last_name" name="last_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-4">
										<label for="email" className="block text-sm font-medium leading-5 text-gray-700">Personal Email Address</label>
										<input defaultValue={this.state.email} disabled="true" required id="email" name="email" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-3 md:gap-6">
							<div className="md:col-span-1">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Tutor Information</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">This information is required for tutors. </p>
							</div>
							<div className="mt-5 md:mt-0 md:col-span-2">
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-6">
										<label for="link" className="block text-sm font-medium leading-5 text-gray-700">Please provide a permanent Google Meets/Zoom link for tutoring sessions</label>
										<input defaultValue={this.state.link} disabled={this.state.readOnly} required id="link" name="link" placeholder="https://meet.google.com/xxx-yyyy-zzz" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-8 border-t border-gray-200 pt-5">
						<p className="text-red-500 text-xs">*It is required to check this box for you to serve as a tutor. Unchecking this box through an edit will remove you as a tutor. </p>
								<input defaultChecked={this.state.tutor} disabled={this.state.readOnly} id="tutor" name="tutor" type="checkbox"></input><label for="tutor" className=" px-1 text-black">I have read through the handbook and agree to take on the responsibility of tutoring students.</label>
							<div className="flex justify-end">
								<input type="submit" value={this.state.submit} className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
								</input>
							</div>
						</div>
					</div>
					</form>
				</div>
		  </div>
		</>
	  );
	}
}