import React, { Component, useEffect } from "react";
import firebase, { UserDB } from "Firebase.js"

// components

export default class Tutor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {readOnly: false, submit: "Submit"};
	}
	
  render() {
	  return (
		<>
		  <div className="flex flex-wrap">
			  <div className="w-full px-4">
					<div className="w-full mx-auto bg-purple-400 rounded-xl shadow-md overflow-hidden flex justify-center md:pt-2 px-4">
						<div class="p-2">
							<div class="tracking-wide text-sm text-purple-1000 text-5xl font-thin text-center pb-4">
								Request a Postcard
							</div>
						<p class="text-white font-thin text-center">
							Answer the following questions to request postcards. 
						</p>
						</div>
					</div>
					<form>
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-3 md:gap-6">
							<div className="md:col-span-1">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">Please list an email at which you can recieve mail at.</p>
							</div>
							<div className="mt-5 md:mt-0 md:col-span-2">
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label for="first_name" className="block text-sm font-medium leading-5 text-gray-700">First Name</label>
										<input required id="first_name" name="first_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-3">
										<label for="last_name" className="block text-sm font-medium leading-5 text-gray-700">Last Name</label>
										<input required id="last_name" name="last_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-4">
										<label for="email" className="block text-sm font-medium leading-5 text-gray-700">Personal Email Address</label>
										<input required type="email" id="email" name="email" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-2">
										<label for="email" className="block text-sm font-medium leading-5 text-gray-700">Phone Number</label>
										<input type="tel" defaultValue={this.state.email} disabled={this.state.readOnly} required id="phone" name="phone" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-3 md:gap-6">
							<div className="md:col-span-1">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Request Information</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">This information is required for requesting postcards. </p>
							</div>
							<div className="mt-5 md:mt-0 md:col-span-2">
								<div className="grid grid-cols-6 gap-6">
								<div className="col-span-6 sm:col-span-6">
										<label for="first_name" className="block text-sm font-medium leading-5 text-gray-700">Volunteer Group (Leave Blank for None)</label>
										<input id="first_name" name="first_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-2">
										<label for="first_name" className="block text-sm font-medium leading-5 text-gray-700">Number of Postcards</label>
										<input  type="number" required id="first_name" name="first_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-4">
										<label for="last_name" className="block text-sm font-medium leading-5 text-gray-700">Campaign</label>
										<input defaultValue={this.state.last} disabled={this.state.readOnly} required id="last_name" name="last_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-6">
										<label for="email" className="block text-sm font-medium leading-5 text-gray-700">Comments</label>
										<input defaultValue={this.state.email} disabled={this.state.readOnly} required id="email" name="email" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-8 border-t border-gray-200 pt-5">
							<div className="flex justify-end">
								<input type="submit" value={this.state.submit} className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
								</input>
							</div>
						</div>
					</div>
					</form>
				</div>
		  </div>
		</>
	  );
	}
}