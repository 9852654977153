import React from "react";
import {hamletData} from '../../assets/files/hamletdata';

export default class Hamlet extends React.Component {
    state = {
        actValue: "",
        sidx: 0,
        eidx: 945,
        acts: [0, 945, 946, 1717, 1718, 2699, 2700, 3449, 3450, 4224],
        idx: 0,
        correct: "",
        skip: false,
        value: ""
    }
    componentDidMount() {
        this.setState({value: "", idx: Math.floor(Math.random() * (this.state.eidx - this.state.sidx) + this.state.sidx), skip: false});
    }
    getQuote = () => {
        
        const spk = hamletData[this.state.idx]["speaker"];
        let i = this.state.idx;
        while(i >= 0 && hamletData[i]["speaker"] == spk){
            i--;
        }
        i++;
        var q = [<p>{hamletData[i]["quote"]}</p>];
        i++;
        console.log(q);
        while(i < hamletData.length && hamletData[i]["speaker"] == spk) {
            q.push(<p>{hamletData[i]["quote"]}</p>);
            i++;
        }
        console.log(q);
        return(q)
    }
    actChange = (e) => {
        const a = parseInt(e.target.value.charAt(3))-1;
        this.setState({actValue: e.target.value, sidx: this.state.acts[a*2], eidx: this.state.acts[(a*2)+1]})
    }
    spkChange = (e) => {
        this.setState({value: e.target.value});
    }
    onSubmit = (e) => {
        e.preventDefault();
        if(!this.state.skip)
            if(e.target.ham.value.toLowerCase() == hamletData[this.state.idx]["speaker"].toLowerCase())
                this.setState({correct: "Correct! The correct speaker is " + hamletData[this.state.idx]["speaker"] + ". Click the Next/Skip button to proceed!"});
            else
                this.setState({correct: "Wrong! The correct speaker is " + hamletData[this.state.idx]["speaker"] + ". Click the Next/Skip button to proceed!"});
    }
    next = () => {
        this.setState({correct: ""});
        this.componentDidMount();
    }
    render() {
        return (
            <div>
                <h1>Hamlet.exe</h1>
                <label for="hamlet">Select an Act:</label>

                <select name="acts" id="acts" onChange={this.actChange} value={this.state.actValue}>
                <option value="act1">Act 1</option>
                <option value="act2">Act 2</option>
                <option value="act3">Act 3</option>
                <option value="act4">Act 4</option>
                <option value="act5">Act 5</option>
                </select>
                <div className="py-4">
                    <p>{this.getQuote()}</p>
                </div>
                <div className="p-2">
                    <form onSubmit={this.onSubmit}>
                        <label for="ham">Who is speaking?</label>
                        <input name="ham" onChange={this.spkChange} value={this.state.value} id="ham" className="mt-1 form-input block py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </input>
                        <div className="py-2 justify-right">
                        <input type="submit" className=" inline-flex rounded-md shadow-sm px-4 inline-flex py-2 border text-sm leading-5 font-medium rounded-md text-black bg-white border-black hover:border-gray-500 hover:text-gray-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
                        </input>
                        <button type="button" onClick={this.next} className=" inline-flex rounded-md shadow-sm px-4 inline-flex py-2 border text-sm leading-5 font-medium rounded-md text-black bg-white border-black hover:border-gray-500 hover:text-gray-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
                            Next/Skip
                        </button>
                        </div>
                    </form>
                </div>
                <div className="py-4">
                    <p>{this.state.correct}</p>
                </div>
            </div>
        )
    }
}