import React from 'react'

import { Link } from 'react-router-dom'
// import { GiTrophiesShelf } from 'react-icons/gi';

// console.log("Number of images: ", this.ref.imgContainer.children.length);

class Menu extends React.Component {
    constructor(prop){
        super(prop);
        let imgSelectedTemp = {}
        const imglist = ['', 'images.tk', 'images.bb', 'images.bm', 'images.bc', 'images.roll', 'images.hotpot', 'images.ct']

        for (let index = 0; index < imglist.length; index++) {
            imgSelectedTemp['image_' + index] = false;
        }

        this.state = {
            isDisabled: false,
            imgSelected: imgSelectedTemp,
            imglist: imglist
        }
    }

    handleClicked(event){
        const id = event.target.id;
        var imgSelectedTemp = this.state.imgSelected;
        imgSelectedTemp[id] = !imgSelectedTemp[id];

        for (let idTemp in imgSelectedTemp) {
            if(imgSelectedTemp[idTemp] === true){
                this.setState({
                    isDisabled: true
                })
                break;
            }
            else{
                this.setState({
                    isDisabled: false
                })
            }
        }
    }


    render(){
        const change = (e) => {e.target.classList.toggle('card-click')}

        const imageList = this.state.imglist;
        return (
        <>
            <div className='app__menu'>
                <div className='app__menu-header'>
                    <div className='header-content'>
                        <h1>
                            MENU
                        </h1>
                        <div className='header-p'>
                            <p>
                                A list of our delicious authentic dishes and cuisine
                            </p>
                        </div>
                    </div>
                    <div className='header-button'>
                        <button type='button' disabled={!this.state.isDisabled} >
                            Order to cart
                        </button>
                    </div>
                </div>
                <div className='app__menu-dash'></div>
                <div className='app__menu-img'>
                    <div className='app__menu-img_container' ref='imgContainer'>
                        {imageList.map((image, index) => (
                            <div 
                            className='app__menu-img_card' 
                            onClick={this.handleClicked.bind(this)}
                            disabled={!this.state.imgSelected['image_'+index]} 
                            key={`menu_img${index + 1}`} 
                            id={'image_' + index}>
                                <div>
                                    <h3 id={'image_' + index}>Com Suon</h3>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
      )
    }
}

// (e) => e.target.classList.toggle('card-click')

export default Menu