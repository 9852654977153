import React, { Component } from "react";
import firebase, { ProjDB } from "Firebase.js";
const pdb = firebase.database().ref("/projects");
const udb = firebase.database().ref("/users");
export default class Plus extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			focusedProj: null,
			save: false,
			projects: [],
			projectDisplay: null
		}
	}
	
	submitProj = (e, proj) => {
		e.stopPropagation()
		
		let data = proj[1]
		data.done = true
		const projectRef = firebase.database().ref("/users/"+this.state.id+"/projects")
		projectRef.child(proj[0]).update(data).then(() => {
			console.log("Project "+ proj[0] + " was updated successfuly with " + data + "successfully!")
			this.componentDidMount()
		}).catch((error) => {
			console.log(error)
		});
	}
	
	removeProject = (id) => {
		console.log(id)
		const projectRef = firebase.database().ref("/users/"+this.state.id+"/projects")
		projectRef.child(id).remove()
		this.componentDidMount()
	}
	
	newProject = () => {
		var projects = this.state.projects
		var unfinishedProj = []
		console.log(this.state.id)
		console.log("Projects: "+this.state.projects)
		if (projects !== undefined) {
			for (var p in projects){
				console.log(projects[p])
				if (projects[p].done !== true){
					console.log("Not Done")
					unfinishedProj.push(p)
				}
			}
		}
		if (unfinishedProj.length >= 3) {
			console.log("TOO MANY PROJECTS!!!")
			alert("A user can only create 3 projects at a time. Please complete your current projects first before creating new ones. ")
		}
		else {
			var now = new Date()
			now.setDate(now.getDate() + 14)
			console.log(now)
			let emptyProj = {
				Title: "Untitled",
				Owner: window.user.email,
				Due: now.getTime()
			}
			const projectRef = firebase.database().ref("/users/"+this.state.id+"/projects")
			projectRef.push(emptyProj).then(() => {
			  console.log("New project created")
			  this.componentDidMount();
		    }).catch((error) => {
			  console.log(error);
		    });
		
		}
	}
	
	handleRequestClick = (e, item) => {
		e.stopPropagation()
		this.removeProject(item)
	}
	
	handleSubmit = (event) => {
		event.preventDefault()
		let data = {
			Title: event.target.title.value,
			Owner: window.user.email,
			Type: event.target.type.value,
			Subject: event.target.subject.value,
			Unit: event.target.unit.value,
			Description: event.target.description.value,
			Link: event.target.link.value
		}
		console.log(data)
		console.log(this.state.focusedProj)
		console.log(this.state.id)
		const projectRef = firebase.database().ref("/users/"+this.state.id+"/projects")
		projectRef.child(this.state.focusedProj[0]).update(data).then(() => {
			console.log("Project "+ this.state.focusedProj + " was updated successfuly with " + data + "successfully!")
			this.setState({save: true})
			this.hideTimeout = setTimeout(() => this.setState({save: false}), 3000)
		}).catch((error) => {
			console.log(error)
		});
	}
	
	componentWillUnmount() {
		clearTimeout(this.hideTimeout)
	}
	
	componentDidMount() {
		firebase.auth().onAuthStateChanged(user => {
		  window.user = user
		  const id = window.user.email
		  udb.orderByChild("id").equalTo(id).once("child_added")
		  .then((dataSnapshot) => {
			const udata = dataSnapshot.key
			console.log(udata);
			this.setState({readOnly: udata[0] !== undefined});
			const ucontents = dataSnapshot.exportVal()
			console.log(ucontents);
			console.log(ucontents.projects)
			if (ucontents == undefined) {
				alert("Please register on the 'profile' page to access all functions of the SOAR portal")
			}
			if (ucontents.projects !== undefined) {
				this.setState({projects: ucontents.projects, id: udata})
			}
			else {
				this.setState({projects: {}, id: udata})
			}
		  })
		})
		console.log("Finished Rendering Page!")
	}
	addProjDisplay = (proj, color) => {
		return(<div className="rounded-xl bg-white text-gray-800 p-4 m-4 w-64 h-68 overflow-hidden hover:bg-gray-50 shadow-md hover:shadow-lg" onClick={() => this.setState({focusedProj: proj})}>
						<div className="flex justify-between">
							<svg height="20px" width="20px">
							  <circle cx="10" cy="10" r="10" stroke-width="0" fill={color} />
							</svg>
							<p className="text-lg line-clamp-2">{proj[1].Title}</p>
							<div onClick={(event) => this.handleRequestClick(event, proj[0])}>
								<svg class="h-6 w-6 fill-current text-red-600 hover:text-red-700" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
								</svg>
							</div>
						</div>
						<p className="text-gray-500">Due: {new Date(proj[1].Due).toString()}</p>
						<div className="border-t border-gray-200 flex justify-end">
							<div className="rounded-xl bg-blue-500 text-white p-2 m-4 hover:bg-blue-600" onClick={(event) => this.submitProj(event, proj)}>
							Submit
							</div>
						</div>
				</div>);
	}
	projectDisplay = () => {
		var projects = []
		for (var p in this.state.projects) {
			if(this.state.projects[p].done !== true) {
					var color;
					  console.log("Adding Unfinished")
							if (this.state.projects[p].Type == "Visual") {
								color = "blue"
							}
							else if (this.state.projects[p].Type == "Video") {
								color = "red"
							}
							else if (this.state.projects[p].Type == "Practice") {
								color = "green"
							}
							else {
								color = "black"
							}
						projects.push(this.addProjDisplay([p, this.state.projects[p]], color));
			}
		}
		if(projects !== null && projects.length>0){
			console.log(projects)
			return(
				<div class="w-full flex justify-start">
					{projects}
				</div>
			)
		}
		else {
			return(
				<div class="w-full flex justify-center">
				<div class="pt-4 text-gray-400 text-lg font-thin flex" >No Projects found, click create new project to get started!</div>
				</div>
			)
		}
	}
	
	saved = () => {
		if (this.state.save == true) {
			console.log("Saved!")
			return(
				<div className="absolute inset-x-0 bottom-0 flex justify-center bg-green-100 text-green-600 p-4">
					Project Saved Successfully
				</div>
			)
		}
	}
	platform = () => {
		console.log(this.state.focusedProj)
		let disp = this.projectDisplay();
		console.log(disp)
		let savebox = this.saved()
		if (this.state.focusedProj !== null){
			let classlist = ["Alg1",
						"Geo", 
						"Alg2", 
						"Precal", 
						"AP Calc", 
						"AP Stats",
						"IH Bio",
						"AP Bio",
						"IH Chem",
						"AP Chem",
						"IH Phys",
						"AP Phys1/2",
						"AP PhysC",
						"AP EnvSci",
						"IH Eng1",
						"IH Eng2",
						"Eng3",
						"Eng4",
						"AP Lit",
						"HGAP",
						"WHAP",
						"APUSH",
						"AP Euro"]
			let dl = []
			for (var k=0;k<classlist.length;k++) {
				dl.push(<option value={classlist[k]}></option>)
			}
			return(
			<div class="relative mt-6 w-full bg-gray-100 rounded-xl shadow-md overflow-y-autopt-2 h-112">
				<form onSubmit={this.handleSubmit}>
				<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-2 md:gap-3">
							<div className="grid grid-cols-3 gap-3 col-span-1">
								<div className="col-span-2 sm:col-span-2">
									<label for="title" className="block text-sm font-medium leading-5 text-gray-700">Title</label>
									<input defaultValue={this.state.focusedProj[1].Title} id="title" name="title" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									</input>
								</div>
								<div className="col-span-1">
									<label for="type" className="block text-sm font-medium leading-5 text-gray-700">Type</label>
									<input list="types" defaultValue={this.state.focusedProj[1].Type} id="type" name="type" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									</input>
									<datalist id="types">
										<option value="Visual">Visual</option>
										<option value="Video">Video</option>
										<option value="Practice">Practice</option>
									</datalist>
								</div>
								<div className="col-span-1 sm:col-span-1">
									<label for="subject" className="block text-sm font-medium leading-5 text-gray-700">Subject</label>
									<input list="subjects" defaultValue={this.state.focusedProj[1].Subject} id="subject" name="subject" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									</input>
									<datalist id="subjects">
										{dl}
									</datalist>
								</div>
								<div className="col-span-2 sm:col-span-2">
									<label for="unit" className="block text-sm font-medium leading-5 text-gray-700">Unit/Topic</label>
									<input defaultValue={this.state.focusedProj[1].Unit} id="unit" name="unit" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									</input>
								</div>
								<div className="col-span-3 sm:col-span-3">
									<label for="link" className="block text-sm font-medium leading-5 text-gray-700">Paste a Google Drive Link to the file</label>
									<input defaultValue={this.state.focusedProj[1].Link} id="link" name="link" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
									</input>
								</div>
							</div>
							<div className="col-span-1">
								<label for="description" className="block text-sm font-medium leading-5 text-gray-700">Description</label>
								<textarea id="description" name="description" className="mt-1 h-48 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
								</textarea>
							</div>
						</div>
						<div className="border-t border-gray-200 mt-4 pt-4 flex justify-between">
							<button onClick={() => {this.setState({focusedProj: null});this.componentDidMount();}}className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
							Return
							</button>
							<input type="submit" value="Save" className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
							</input>
						</div>
				</div>
				</form>
				{savebox}
			</div>
			)
		}
		else {
			return(
				<div class="mt-6 w-full bg-gray-100 rounded-xl shadow-md overflow-y-autopt-2 h-112">
					<div class="border-b border-gray-200 bg-white w-full flex rounded-xl justify-between">
						<div className="text-gray-600 p-8 text-2xl">
							SOAR Plus
						</div>
						<button className="group flex rounded-xl p-4 m-4 shadow-lg bg-white hover:bg-gray-25 hover:shadow-xl text-gray-800 hover: text-gray-900 text-lg" onClick={this.newProject}>
							<img className="group-hover:opacity-80 mr-4 w-8 h-8" src={require("assets/imgs/plus.png").default}></img>
							New
						</button>
					</div>
					{disp}
				</div>
			)
		}
	}
	
	render() {
		return(
			<div className="">
				<div className="flex flex-wrap">
					<div className="w-full px-4">
						<this.platform />
					</div>
				</div>
			</div>
		)
	}
}