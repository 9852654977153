import React, { Component, useEffect } from "react";
import firebase, { UserDB, SessDB } from "Firebase.js"

// components
const sdb = firebase.database().ref("/sessions")
const udb = firebase.database().ref("/users");
export default class Student extends React.Component {
	constructor(props) {
		super(props);
		this.state = {readOnly: false, submit: "Submit"};
	}
	
  handleSubmit = (event) => {
	  event.preventDefault()
	  if (this.state.submit == "Submit") {
		  let data = {
			  first: event.target.first_name.value,
			  last: event.target.last_name.value,
			  email: event.target.email.value,
			  student: event.target.student.checked,
			  id: window.user.email
		  };
		  if (data.first == ""){
			  alert("Please register on the Profile Page first!")
		  }
		  else {
			  console.log(data);
		  if (this.state.id == undefined) {
			  UserDB.create(data).then(() => {
				  console.log("Created User with items" + data + "successfully!");
			  }).catch((error) => {
				  console.log(error);
			  });
		  }
		  else {
			  UserDB.update(this.state.id, data).then(() => {
				  console.log("Item " + this.state.id + "was updated with " + data + "successfully!")
			  }).catch((error) => {
				  console.log(error);
			  });
		  }
		  //window.location.reload();
		  this.componentDidMount();
		  }
		  
	  }
	  else {
		  this.setState({readOnly: false, submit: "Submit"});
	  }
  }
  componentDidMount() {
	  firebase.auth().onAuthStateChanged(user => {
	  window.user = user
	  const id = window.user.email
	  udb.orderByChild("id").equalTo(id).once("child_added")
	  .then((dataSnapshot) => {
		const udata = dataSnapshot.key
		console.log(udata);
		this.setState({readOnly: udata !== undefined});
		const ucontents = dataSnapshot.exportVal()
		console.log(ucontents);
		if (ucontents !== undefined && ucontents.student == true) {
			this.setState({id: udata, first: ucontents.first, last: ucontents.last, email: ucontents.email, student: ucontents.student, submit: "Edit"})
		}
		if (ucontents.student !== true) {
			this.setState({id: udata, first: ucontents.first, last: ucontents.last, email: ucontents.email, readOnly: false, submit: "Submit"});
	  }
	  })
    })
  }
  sessionList = () => {
	  firebase.auth().onAuthStateChanged(user => {
		  window.user = user
		  const id = window.user.email
		  var htmlSess = [];
		  var finishedSess = [];
		  var finalSess = [];
		  sdb.orderByChild("id").equalTo(id).once("value", (snapshot) => {
			snapshot.forEach((childSnapshot) => {
				const sessDate = new Date(childSnapshot.val().date);
				const q = new Date()
				var m = q.getMonth()
				var d = q.getDate()
				var y = q.getFullYear()
				const currDate = new Date(y, m, d)
				if(sessDate > currDate){
					var time = childSnapshot.val().time.split(":")
					if(time[0] > 12){
						var temp_time = time[0] - 12 
						var timestr = temp_time + ":" + time[1] + " PM"
					}
					else if(time[0] == 0) {
						var timestr = 12 + ":" + time[1] + " AM"
					}
					else if(time[0] < 12 && time[0] > 0) {
						var timestr = time[0] + ":" + time[1] + " AM"
					}
					else {
						var timestr = time[0] + ":" + time[1] + " PM"
					}
					if(childSnapshot.val().tutor != undefined){
						var tutor_stuff = UserDB.getContents(childSnapshot.val().tutor)
						console.log("tutor_stuff: " + tutor_stuff)
						htmlSess.push(
						<>
							<div className="md:grid md:grid-cols-12 md:gap-6 p-2">
								<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-black-500">{childSnapshot.val().date}</p></div>
								<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{timestr}</p></div>
								<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().subject}</p></div>
								<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().unit}</p></div>
								<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 font-medium text-black">{childSnapshot.val().tutor}</p></div>
								<div className="md:col-span-3"><a href={tutor_stuff[0].link} class="mt-1 text-sm leading-5 text-blue-500 font-medium hover:text-blue-600">{tutor_stuff[0].link}</a></div>
							</div>
						<hr></hr>
						</>
					)
					}
					else {
					htmlSess.push(
					<>
					<div className="md:grid md:grid-cols-12 md:gap-6 p-2">
						<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-black-500">{childSnapshot.val().date}</p></div>
						<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{timestr}</p></div>
						<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().subject}</p></div>
						<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().unit}</p></div>
						<div className="md:col-span-5"><p class="mt-1 text-sm leading-5 text-gray-500">Session request has not been filled yet</p></div>
					</div>
					<hr></hr>
					</>
					)}
				}
			else {
				var time = childSnapshot.val().time.split(":")
					if(time[0] > 12){
						var temp_time = time[0] - 12 
						var timestr = temp_time + ":" + time[1] + " PM"
					}
					else if(time[0] == 0) {
						var timestr = 12 + ":" + time[1] + " AM"
					}
					else if(time[0] < 12 && time[0] > 0) {
						var timestr = time[0] + ":" + time[1] + " AM"
					}
					else {
						var timestr = time[0] + ":" + time[1] + " PM"
					}
					if(childSnapshot.val().tutor != undefined){
						var tutor_stuff = UserDB.getContents(childSnapshot.val().tutor)
						console.log("tutor_stuff: " + tutor_stuff)
						finishedSess.push(
						<>
							<div className="md:grid md:grid-cols-12 md:gap-6 p-2">
								<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-black-500">{childSnapshot.val().date}</p></div>
								<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{timestr}</p></div>
								<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().subject}</p></div>
								<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().unit}</p></div>
								<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 font-medium text-black">{childSnapshot.val().tutor}</p></div>
								<div className="md:col-span-3"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.key}</p></div>
							</div>
						<hr></hr>
						</>
					)
					}
					else {
					finishedSess.push(
					<>
					<div className="md:grid md:grid-cols-12 md:gap-6 p-2">
						<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-black-500">{childSnapshot.val().date}</p></div>
						<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{timestr}</p></div>
						<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().subject}</p></div>
						<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.val().unit}</p></div>
						<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 text-gray-500">Session request was not filled and has expired</p></div>
						<div className="md:col-span-3"><p class="mt-1 text-sm leading-5 text-gray-500">{childSnapshot.key}</p></div>
					</div>
					<hr></hr>
					</>
					)}
			}
		  })
		}).then(() => {
			if(htmlSess.length > 0){
				finalSess.push(
				<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
							<div className="py-4">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Sessions</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">The tutoring sessions that you have signed up for should appear here. </p>
							</div>
							<div className="mt-5 md:mt-0">
								<div className="bg-gray-50">
									<div className="md:grid md:grid-cols-12 md:gap-6 p-2">
										<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-black-500">Date</p></div>
										<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Time</p></div>
										<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Subject</p></div>
										<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Unit</p></div>
										<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Tutor's Email</p></div>
										<div className="md:col-span-3"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Link</p></div>
									</div>
									<hr></hr>
								</div>
								{htmlSess}
							</div>
					</div>
				)
			}
			if(finishedSess.length > 0){
				finalSess.push(
					
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
							<div className="py-4">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Finished Sessions</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">The tutoring sessions that have expired/finished will be listed here for reference. </p>
							</div>
							<div className="mt-5 md:mt-0">
								<div className="bg-gray-50">
									<div className="md:grid md:grid-cols-12 md:gap-6 p-2">
										<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-black-500">Date</p></div>
										<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Time</p></div>
										<div className="md:col-span-1"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Subject</p></div>
										<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Unit</p></div>
										<div className="md:col-span-2"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Tutor's Email</p></div>
										<div className="md:col-span-3"><p class="mt-1 text-sm leading-5 font-medium text-gray-500">Session ID</p></div>
									</div>
									<hr></hr>
								</div>
								{finishedSess}
							</div>
					</div>
				)
			}
			this.setState({sessions: 
			<div>
			{finalSess}
			</div>})
		})
	  })
  }
  render() {
	  console.log(this.state.sessions)
	  if(this.state.sessions == undefined){
		this.sessionList()
	  }
	  console.log(this.state)
	  return (
		<>
		  <div className="flex flex-wrap">
			  <div className="w-full px-4">
					<div className="w-full mx-auto bg-blue-600 rounded-xl shadow-md overflow-hidden flex justify-center md:pt-2 px-4">
						<div class="p-2">
							<div class="tracking-wide text-sm text-yellow-300 text-5xl font-thin text-center pb-4">
								For Students
							</div>
						<p class="text-white font-thin text-center">
							Confirm your information, then agree to the following statement below to sign up as a student. (If the boxes are blank, please make sure to register as a member in the profile section.)
						</p>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-3 md:gap-6">
							<div className="md:col-span-1">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">If you have already registered as a member of SOAR, confirm your personal information. Otherwise, please register on the Profile page first. </p>
							</div>
							<div className="mt-5 md:mt-0 md:col-span-2">
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label for="first_name" className="block text-sm font-medium leading-5 text-gray-700">First Name</label>
										<input defaultValue={this.state.first} disabled="true" required id="first_name" name="first_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-3">
										<label for="last_name" className="block text-sm font-medium leading-5 text-gray-700">Last Name</label>
										<input defaultValue={this.state.last} disabled="true" required id="last_name" name="last_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-4">
										<label for="email" className="block text-sm font-medium leading-5 text-gray-700">Personal Email Address</label>
										<input defaultValue={this.state.email} disabled="true" required id="email" name="email" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
								</div>
							</div>
						</div>
						<div className="mt-8 border-t border-gray-200 pt-5">
						<p className="text-red-500 text-xs">*It is required to check this box for you to sign up as a student. Unchecking this box through an edit will remove you as a student. </p>
								<input defaultChecked={this.state.student} disabled={this.state.readOnly} id="student" name="student" type="checkbox"></input><label for="student" className=" px-1 text-black">I have read through the handbook and agree to take on the responsibility of attending the tutoring sessions that I sign up for.
</label>
							<div className="flex justify-end">
								<input type="submit" value={this.state.submit} className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
								</input>
							</div>
						</div>
					</div>
					</form>
					{this.state.sessions}
				</div>
		  </div>
		</>
	  );
	}
}