import React from "react";
import { Link } from "react-router-dom";

export default function Navbar(props) {
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-blue-900 shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
			  <Link
				  to="/"
				  className="text-white text-sm font-bold leading-relaxed inline-block mr-4 whitespace-no-wrap uppercase">
				  <img className="inline-block mr-4" src={require("assets/imgs/logo.png").default} style={{ height: 50 }} />
				  Student Organized Academic Refinement
				</Link>
          </div>
        </div>
      </nav>
    </>
  );
}