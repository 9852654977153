import React, { Component, useEffect } from "react";
import firebase, { UserDB, SessDB } from "Firebase.js"
import YouTube from 'react-youtube'; //npm install react-youtube
import { data } from "autoprefixer";
let config = {
    apiKey: "",
    authDomain: "",
    databaseURL: ""
};

//const googleProvider = new firebase.auth.GoogleAuthProvider()
const udb = firebase.database().ref("/users");
const vdb = firebase.database().ref("/elections");
// components
//setup tab switch detection system 
let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}
// components
const sdb = firebase.database().ref("/sessions")
export default class Vote extends React.Component {
	constructor(props) {
		super(props);
		this.state = {id: null, readOnly: false, submit: "Submit", done: false};
	}
	
    update(key, value) { //updates the user
		return udb.child(key).update(value);
	}
	getUser = (id) => { //gets the id of the user given their email, which is a global variable
	 
	  return;
	}

    countCheckBoxes = (results) => {
        console.log(results);
        var count = 0;
        Object.values(results).forEach(function(i) {
            if(i == 1){
                count++;
            }
        });
        console.log(count);
        return count;
    }

    // Updates votes by adding new votes to old ones from firebase. 
    updateVotes =(results) => {
        const id = "2022";
        vdb.child(id).get().then((snapshot) => {
            console.log(snapshot.val())
            for(let i of Object.keys(snapshot.val())){
                results[i] += snapshot.val()[i];
            }
            console.log("Votes has been updated with " + results);
            UserDB.update(this.state.id, {voted: true}).then(() => {
                console.log("Item " + this.state.id + "was updated with " + data + "successfully!")
            }).catch((error) => {
                console.log(error);
            });
            vdb.child(id).update(results);
            this.componentDidMount();
        }).catch((error) => {
            console.error(error);
        });
    }

    handleSubmit = (event) => {
        event.preventDefault()
            let data = {
                c1: Number(event.target.c1.checked), 
                c2: Number(event.target.c2.checked), 
                c3: Number(event.target.c3.checked), 
                c4: Number(event.target.c4.checked), 
                c5: Number(event.target.c5.checked), 
                c6: Number(event.target.c6.checked), 
                c7: Number(event.target.c7.checked), 
                c8: Number(event.target.c8.checked)

            };
            if (window.user.email.substr(window.user.email.length-11)!=="@mypisd.net"){
                alert("Please use your pisd email.")
            }
            else if (this.countCheckBoxes(data) !== 2){
                alert("You can only vote for 2 candidates, no more or no less.")
            }
            else {
                console.log(data);
                this.updateVotes(data);
            }
            //window.location.reload();
            
    }
    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
        window.user = user
        const id = window.user.email
        udb.orderByChild("id").equalTo(id).once("child_added")
            .then((dataSnapshot) => {
                const udata = dataSnapshot.key
                console.log(udata);
                this.setState({readOnly: udata !== undefined});
                const ucontents = dataSnapshot.exportVal()
                console.log(ucontents);
                if (ucontents !== undefined) {
                    this.setState({id: udata, first: ucontents.first, last: ucontents.last, email: ucontents.email, student: ucontents.student, submit: "Edit", done: ucontents.done, voted: ucontents.voted})
                }
            })
        })
    }
    
    videoPlayer = () => {
        const opts = {
            width: '972',
            height: '620',
            playerVars: {
                controls:0, //disables the skip time bar
                disablekb:1 //disables keyboard controls, idk if this works on other browsers
            },
            }; //the video in videoId is a test
        if(this.state.done != true){
            return (
                <div className="flex flex-wrap">
                    <div className="w-full px-4 flex justify-center">
                        <YouTube videoId="InvhaEyZIOU" opts={opts} onReady={this._onReady} onStateChange={(e) => {this.onPlayerStateChange(e);}}/>
                    </div>
                </div>
            )}
        else if(this.state.voted == true){
            return(<div>Thank you for participating in the SOAR 2022 Elections! Results will be released after Spring Break. </div>)
        }
        else {
            console.log("Finished!")
            return (
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">Voting</h3>
                        <p class="mt-1 text-sm leading-5 text-gray-500">Congratulations! You have finished watching all of the candidate videos. Now it is time to vote. Please select <b>two candidates</b> that you think are suitable to serve as SOAR President and Vice President next year. </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c1" name="c1" type="checkbox"></input>
                            <label for="c1" className=" px-1 text-black">
                                Rayyan Khan
                            </label>
                        </div>
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c2" name="c2" type="checkbox"></input>
                            <label for="c2" className=" px-1 text-black">
                                Sana Shahid
                            </label>
                        </div>
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c3" name="c3" type="checkbox"></input>
                            <label for="c3" className=" px-1 text-black">
                                Namrata Iyer
                            </label>
                        </div>
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c4" name="c4" type="checkbox"></input>
                            <label for="c4" className=" px-1 text-black">
                                Reyan Patel
                            </label>
                        </div>
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c5" name="c5" type="checkbox"></input>
                            <label for="c5" className=" px-1 text-black">
                                Ezyan Bhayani
                            </label>
                        </div>
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c6" name="c6" type="checkbox"></input>
                            <label for="c6" className=" px-1 text-black">
                                Amitha Mandava
                            </label>
                        </div>
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c7" name="c7" type="checkbox"></input>
                            <label for="c7" className=" px-1 text-black">
                                Emmanuel Tawiah
                            </label>
                        </div>
                        <div className="pl-4 pb-2">
                            <input defaultChecked={this.state.voted} disabled={this.state.voted}  id="c8" name="c8" type="checkbox"></input>
                            <label for="c8" className=" px-1 text-black">
                                Het Koradia
                            </label>
                        </div>
                        <div className="flex justify-end">
							<input type="submit" disabled={this.state.voted} value="Submit" className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
							</input>
						</div>
                    </div>
                    
                </div>
            )
        }
    }

    render() {
        
	    console.log(this.state)
	    return (
            <>
            <div className="flex flex-wrap">
                <div className="w-full px-4">
                        <div className="w-full mx-auto bg-blue-600 rounded-xl shadow-md overflow-hidden flex justify-center md:pt-2 px-4">
                            <div class="p-2">
                                <div class="tracking-wide text-sm text-yellow-300 text-5xl font-thin text-center pb-4">
                                    Vote
                                </div>
                            <p class="text-white font-thin text-center">
                                Watch the videos (the videoplayer will pause if you switch to another tab) and vote for the candidate you think is best fit for SOAR President or Vice President. Please make sure you are signed in on your pisd account and have registered in <a className="text-yellow-300" href="https://portal.soarlearn.org/profile">profile</a>
                            </p>
                            </div>
                        </div>
                        <form onSubmit={this.handleSubmit}>
                        <div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                            <this.videoPlayer />
                        </div>
                        </form>
                    </div>
            </div>
            </>
	    );
	}

    _onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
        const tar = event.target;
        document.addEventListener(visibilityChange, () => this.tabSwitch(tar));
        if(this.state.id==null){
            alert("Please fill out all questions in the profile page at https://portal.soarlearn.org/member before watching the video. ");
        }
        else if(window.user.email.substr(window.user.email.length-11)!=="@mypisd.net"){
            alert("You are signed in on a non-pisd email. Please sign in using your pisd email in order to participate in the vote.")
        }
    }

    onPlayerStateChange = (event) => { //adds done attribute to user when video is done       
    if(event.data === 0) {
        udb.orderByChild("id").equalTo(window.user.email).once("child_added")
        .then((dataSnapshot) => {
            console.log(dataSnapshot.key);
            const id = dataSnapshot.key;
            console.log(id);
            let data = {done: true};
            this.update(id, data).then(() => {
                    console.log("Item " + id + "was updated with " + data + "successfully!")
                    window.location.reload();
                    }).catch((error) => {
                        console.log(error);
                });
            });
        }
    }
    //pauses when switches tab, plays when returns to tab. 
    tabSwitch(tar){
        if(document[hidden]){
            console.log("Hidden");
            tar.pauseVideo();
        }
        else {
            tar.playVideo();
        }
}
}