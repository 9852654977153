import React, { Component, useEffect } from "react";
import firebase, { UserDB } from "Firebase.js"

// components
const udb = firebase.database().ref("/users");
export default class Member extends React.Component {
	constructor(props) {
		super(props);
		this.state = {readOnly: false, submit: "Submit"};
	}
	
  handleSubmit = (event) => {
	  event.preventDefault()
	  if (this.state.submit == "Submit") {
		  let data = {
			  first: event.target.first_name.value,
			  last: event.target.last_name.value,
			  email: event.target.email.value,
			  grade: event.target.grade.value,
			  bylaws: event.target.bylaws.checked,
			  classroom: event.target.classroom.checked,
			  cheating: event.target.cheating.checked,
			  id: window.user.email,
			  school: event.target.school.value
		  };
		  //console.log(data);
		  //console.log(window.user.email)
		  /*
		  const userloc = UserDB.getUser(window.user.email)
		  console.log(JSON.stringify(userloc))
		  if (userloc.length == 0) {
			  UserDB.create(data).then(() => {
				  console.log("Created User with items" + data + "successfully!");
			  }).catch((error) => {
				  console.log(error);
			  });
		  }
		  */
		  //console.log(this.state.key);
		  if(this.state.key == undefined){
			UserDB.create(data).then(() => {
				//console.log("Created User with items" + data + "successfully!");
				this.forceUpdate()
			}).catch((error) => {
				console.log(error);
			});
		  }
		  else {
			  UserDB.update(this.state.key, data).then(() => {
				  //console.log("Item " + this.state.key + "was updated with " + data + "successfully!")
				  this.forceUpdate()
			  }).catch((error) => {
				  console.log(error);
			  });
		  }
		  //window.location.reload();
		  this.componentDidMount();
	  }
	  else {
		  this.setState({readOnly: false, submit: "Submit"});
	  }
  }
  
  handleClassSubmit = (event) => {
	  event.preventDefault()
	  const data = event.target.class.value
	  //console.log(data);
	  //console.log(window.user.email)
	  const userloc = UserDB.getUser(window.user.email)
	  //console.log(userloc)
	  const classesRef = firebase.database().ref("/users/"+userloc[0]+"/classes")
	  classesRef.push(data).then(() => {
		  //console.log("Item " + userloc + "/classes was updated with " + data + "successfully!")
		  this.componentDidMount();
	  }).catch((error) => {
		  console.log(error);
	  });
	  //window.location.reload();
  }
  
  handleRequestClick = (e, c) => {
	e.stopPropagation()
	//console.log(c)
	const userloc = UserDB.getUser(window.user.email)
    //console.log(userloc)
    const classesRef = firebase.database().ref("/users/"+userloc[0]+"/classes")
	classesRef.child(c).remove().then(() => {
		  //console.log("Item " + userloc + "/classes removed " + c + "successfully!")
		  this.componentDidMount();
	  }).catch((error) => {
		  console.log(error);
	  });
  }
	
  classTable = () => {
	  var classes = []
	  //console.log(this.state.classes)
	  for(const c in this.state.classes){
		  classes.push(
		  <tr className="px-4 py-2 border-b">
			  <td className="px-4 py-2 border-b mt-1 text-sm leading-5 text-gray-500">
			      {this.state.classes[c]}
			  </td>
			  <td className="flex justify-end px-4 py-2 border-b">
					<svg onClick={(event) => this.handleRequestClick(event, c)} class="h-5 w-5 fill-current text-red-600 hover:text-red-700" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/>
					</svg>
			  </td>
		  </tr>
		  )
	  }
	  classes.push(
		  <tr className="px-4 py-2 border-b bg-gray-50">
			  <td className="px-4 py-2 border-b mt-1 text-sm leading-5 text-gray-500">
			      <input name="class" id="class" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
				  </input>
			  </td>
			  <td className="px-4 py-2 border-b mt-1 text-sm leading-5 text-gray-500 flex justify-end">
			      <input type="submit" value="Add" className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
				  </input>
			  </td>
		  </tr>
	  )
	  return(classes)
  }
  
  componentDidMount() {
	  firebase.auth().onAuthStateChanged(user => {
	  window.user = user
	  const id = window.user.email
	  udb.orderByChild("id").equalTo(id).once("child_added")
	  .then((dataSnapshot) => {
		const udata = dataSnapshot.key
		//console.log(udata);
		this.setState({readOnly: udata[0] !== undefined, key: udata});
		const ucontents = dataSnapshot.exportVal()
		//console.log(ucontents);
		if (ucontents !== undefined && ucontents.bylaws == true && ucontents.cheating == true && ucontents.classroom == true) {
			this.setState({readonly: true, first: ucontents.first, last: ucontents.last, email: ucontents.email, grade: ucontents.grade, bylaws: ucontents.bylaws, classroom: ucontents.classroom, cheating: ucontents.cheating, submit: "Edit", classes: ucontents.classes, school: ucontents.school})
		}
		if (ucontents.bylaws == false && ucontents.cheating == false && ucontents.classroom == false) {
			this.setState({readOnly: false, submit: "Submit"});
	  }
	  })
	  //const udata = UserDB.getUser(window.user.email)
	  //console.log(udata);
	  //console.log(udata.length);
	  //console.log(udata.length !== 0);
	  //console.log(udata[0]);
	  /*this.setState({readOnly: udata[0] !== undefined});
	  const ucontents = UserDB.getContents(window.user.email)[0]
	  console.log(ucontents);
	  if (ucontents !== undefined && ucontents.tutor == true) {
		this.setState({first: ucontents.first, last: ucontents.last, email: ucontents.email, link: ucontents.link, tutor: ucontents.tutor, submit: "Edit"})
	  }
	  if (ucontents.tutor == false) {
		  this.setState({readOnly: false, submit: "Submit"});
	  }
	  */
    })
  }
  render() {
		let schoollist = ["Plano East Senior High School",
	"Williams High School",
	"McMillen High School",
	"Otto Middle School"]
	let dl = []
	for (var i = 0; i < schoollist.length; i++) {
	dl.push(<option value={schoollist[i]}></option>)
	}
	  return (
		<>
		  <div className="flex flex-wrap">
			  <div className="w-full px-4">
					<div className="w-full mx-auto bg-blue-600 rounded-xl shadow-md overflow-hidden flex justify-center md:pt-2 px-4">
						<div class="p-2">
							<div class="tracking-wide text-sm text-yellow-300 text-5xl font-thin text-center pb-4">
								Registration
							</div>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-3 md:gap-6">
							<div className="md:col-span-1">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">Please use a personal email account that you can receive mail at.</p>
							</div>
							<div className="mt-5 md:mt-0 md:col-span-2">
								<div className="grid grid-cols-6 gap-6">
									<div className="col-span-6 sm:col-span-3">
										<label for="first_name" className="block text-sm font-medium leading-5 text-gray-700">First Name</label>
										<input defaultValue={this.state.first} disabled={this.state.readOnly} required id="first_name" name="first_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-3">
										<label for="last_name" className="block text-sm font-medium leading-5 text-gray-700">Last Name</label>
										<input defaultValue={this.state.last} disabled={this.state.readOnly} required id="last_name" name="last_name" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-4">
										<label for="email" className="block text-sm font-medium leading-5 text-gray-700">Personal Email Address</label>
										<input defaultValue={this.state.email} disabled={this.state.readOnly} required id="email" name="email" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-2">
										<label for="grade" className="block text-sm font-medium leading-5 text-gray-700">Grade</label>
										<input type="number" defaultValue={this.state.grade} disabled={this.state.readOnly} required id="grade" name="grade" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
									</div>
									<div className="col-span-6 sm:col-span-6">
										<label for="school" className="block text-sm font-medium leading-5 text-gray-700">School</label>
										<input type="" defaultValue={this.state.school} disabled={this.state.readOnly} list="schools" required id="school" name="school" className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5">
										</input>
										<datalist id="schools">{dl}</datalist>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
						<div className="md:grid md:grid-cols-3 md:gap-6">
							<div className="md:col-span-1">
								<h3 className="text-lg font-medium leading-6 text-gray-900">Agreements</h3>
								<p class="mt-1 text-sm leading-5 text-gray-500">Please read and confirm acceptance of all conditions and bylaws. </p>
							</div>
							<div className="md:col-span-2">
								<div className="border-b border-gray-100 p-4">
									<input defaultChecked={this.state.bylaws} disabled={this.state.readOnly} required="required" id="bylaws" name="bylaws" type="checkbox"></input>
									<label for="bylaws" className=" px-1 text-black">
										I confirm that I have read the SOAR handbook.
									</label>
								</div>
								<div className="border-b border-gray-100 p-4">
									<input defaultChecked={this.state.classroom} disabled={this.state.readOnly} required="required" id="classroom" name="classroom" type="checkbox"></input>
									<label for="classroom" className=" px-1 text-black">
										I confirm that I have joined my school's SOAR Google Classroom Page where my school's SOAR Chapter will post updates
									</label>
								</div>
								<div className="p-4">
									<input defaultChecked={this.state.cheating} disabled={this.state.readOnly} required="required" id="cheating" name="cheating" type="checkbox"></input>
									<label for="cheating" className=" px-1 text-black">
										Because SOAR was founded in Plano East, SOAR and its members adhere to the Plano East honor code: 
										“Plano East, in a relentless effort to maintain high moral and ethical standards, is intolerant of academic dishonesty in any form. 
										Cheating undermines both the integrity of the perpetrator as well as that of the school. In presenting an honor code of conduct based on individual integrity and ethics, 
										we aspire to create a vision of what we would like our community to be. 
										The honor code intends to reduce unhealthy competition in the school by shifting peer pressure away from cheating and towards ethical behavior.”
									</label>
								</div>
							</div>
						</div>
						<div className="flex justify-end">
							<input type="submit" value={this.state.submit} className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out">
							</input>
						</div>
					</div>
					</form>
					<form onSubmit={this.handleClassSubmit}>
						<div className="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
							<div className="md:grid md:grid-cols-3 md:gap-6">
								<div className="md:col-span-1">
									<h3 className="text-lg font-medium leading-6 text-gray-900">Classes</h3>
									<p class="mt-1 text-sm leading-5 text-gray-500">Enter classes that you would like SOAR to prepare study sessions for. </p>
								</div>
								<div className="mt-5 md:mt-0 md:col-span-2">
									<div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
										<table className="min-w-full">
											<tr className="bg-gray-50 border-b">
												<th className="mt-1 text-sm leading-5 font-medium text-gray-500 flex justify-left px-4 py-2">
													Class
												</th>
												<th>
												</th>
											</tr>
											<this.classTable />
										</table>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
		  </div>
		</>
	  );
	}
}