import React, { Component } from "react";
import moment from "moment";
import firebase, { UserDB, SessDB } from "Firebase.js";
// components
const udb = firebase.database().ref("/users");
export default class Calendar extends React.Component {
    state = {
        dateContext: moment(),
        today: moment(),
        showMonthPopup: false,
        showYearPopup: false,
        selectedDay: null,
		sess: undefined,
		contents: undefined
    }

    constructor(props) {
        super(props);
        this.width = props.width || "0px";
        this.style = props.style || {};
        this.style.width = this.width; // add this
    }

	componentDidMount() {
	  firebase.auth().onAuthStateChanged(user => {
	  window.user = user
	  const id = window.user.email
	  udb.orderByChild("id").equalTo(id).once("child_added")
	  .then((dataSnapshot) => {
		const udata = dataSnapshot.key
		console.log(udata);
		this.setState({readOnly: udata[0] !== undefined});
		const ucontents = dataSnapshot.exportVal()
		console.log(ucontents);
		this.setState({ucontents: ucontents})
	  })
    })
  }
    weekdays = moment.weekdays(); //["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]
    weekdaysShort = moment.weekdaysShort(); // ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
    months = moment.months();

    year = () => {
        return this.state.dateContext.format("Y");
    }
    month = () => {
        return this.state.dateContext.format("MMMM");
    }
    daysInMonth = () => {
        return this.state.dateContext.daysInMonth();
    }
    currentDate = () => {
        console.log("currentDate: ", this.state.dateContext.get("date"));
        return this.state.dateContext.get("date");
    }
    currentDay = () => {
        return this.state.dateContext.format("D");
    }

    firstDayOfMonth = () => {
        let dateContext = this.state.dateContext;
        let firstDay = moment(dateContext).startOf('month').format('d'); // Day of week 0...1..5...6
        return firstDay;
    }

    setMonth = (month) => {
        let monthNo = this.months.indexOf(month);
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).set("month", monthNo);
        this.setState({
            dateContext: dateContext
        });
    }

    nextMonth = () => {
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).add(1, "month");
        this.setState({
            dateContext: dateContext
        });
        this.props.onNextMonth && this.props.onNextMonth();
    }

    prevMonth = () => {
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).subtract(1, "month");
        this.setState({
            dateContext: dateContext
        });
        this.props.onPrevMonth && this.props.onPrevMonth();
    }

    onSelectChange = (e, data) => {
        this.setMonth(data);
        this.props.onMonthChange && this.props.onMonthChange();

    }
    SelectList = (props) => {
        let popup = props.data.map((data) => {
            return (
                <div key={data}>
                    <a href="#" onClick={(e)=> {this.onSelectChange(e, data)}}>
                        {data}
                    </a>
                </div>
            );
        });

        return (
            <div className="month-popup">
                {popup}
            </div>
        );
    }

    onChangeMonth = (e, month) => {
        this.setState({
            showMonthPopup: !this.state.showMonthPopup
        });
    }

    MonthNav = () => {
        return (
            <span className="hover:text-yellow-200"
                onClick={(e)=> {this.onChangeMonth(e, this.month())}}>
                {this.month()}
                {this.state.showMonthPopup &&
                 <this.SelectList data={this.months} />
                }
            </span>
        );
    }

    showYearEditor = () => {
        this.setState({
            showYearNav: true
        });
    }

    setYear = (year) => {
        let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).set("year", year);
        this.setState({
            dateContext: dateContext
        })
    }
    onYearChange = (e) => {
        this.setYear(e.target.value);
        this.props.onYearChange && this.props.onYearChange(e, e.target.value);
    }

    onKeyUpYear = (e) => {
        if (e.which === 13 || e.which === 27) {
            this.setYear(e.target.value);
            this.setState({
                showYearNav: false
            })
        }
    }

    YearNav = () => {
        return (
            this.state.showYearNav ?
            <input
                defaultValue = {this.year()}
                className="editor-year"
                ref={(yearInput) => { this.yearInput = yearInput}}
                onKeyUp= {(e) => this.onKeyUpYear(e)}
                onChange = {(e) => this.onYearChange(e)}
                type="number"
                placeholder="year"/>
            :
            <span
                className="label-year"
                onDoubleClick={(e)=> { this.showYearEditor()}}>
                {this.year()}
            </span>
        );
    }

    onDayClick = (e, day) => {
		let dateContext = Object.assign({}, this.state.dateContext);
        dateContext = moment(dateContext).set("date", day);
        this.setState({
            selectedDay: day,
			dateContext: dateContext
        }, () => {
            console.log("SELECTED DAY: ", this.state.selectedDay);
			console.log("DATE: ", this.state.dateContext);
        });

        this.props.onDayClick && this.props.onDayClick(e, day);
    }
	
	showSideBar = () => {
		let classlist = ["Alg1",
						"Geo", 
						"Alg2", 
						"Precal", 
						"AP Calc", 
						"AP Stats",
						"IH Bio",
						"AP Bio",
						"IH Chem",
						"AP Chem",
						"IH Phys",
						"AP Phys1/2",
						"AP PhysC",
						"AP EnvSci",
						"IH Eng1",
						"IH Eng2",
						"Eng3",
						"Eng4",
						"AP Lit",
						"HGAP",
						"WHAP",
						"APUSH",
						"AP Euro"]
		let dl = []
		for (var i = 0; i < classlist.length; i++) {
			dl.push(<option value={classlist[i]}></option>)
		}
		const ucontents = this.state.ucontents;
		if (ucontents !== undefined) {
			if ( ucontents.student == true ) {
				if (this.state.selectedDay !== null) {
					return(
						<form onSubmit={this.handleSubmit}>
						<div className="grid grid-cols-3 gap-6 text-left py-4">
							<div className="col-span-1">
							<label for="date" className="block text-sm font-medium leading-5 text-gray-700">Date:</label>
							 <input readOnly id="date" name="date" value={this.state.dateContext.format('MM/DD/YYYY')} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
							</div>
							<div className="col-span-2">
								<label name="subject" for="subject" className="block text-sm font-medium leading-5 text-gray-700">Subject: </label>
								<input name="subject" id="subject" list="tutor-classes" required className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
								<datalist id="tutor-classes">{dl}</datalist>
							</div>
							<div className="col-span-1">
								<label name="time" for="time" className="block text-sm font-medium leading-5 text-gray-700">Time of Session: </label>
								<input type="time" id="time" name="time" required className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
							</div>
							<div className="col-span-2">
								<label name="unit" for="unit" className="block text-sm font-medium leading-5 text-gray-700">Please input units you need help in (i.e. quadratics)</label>
								<input name="unit" id="unit" required className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
							</div>
						</div>
							<div className="border-t border-gray-200">
								<input id = "checked" type="checkbox" name = "checked" required></input>
								<label for="checked" name="checked" className="p-1">I understand that it is my responsibility to attend the session if a tutor fills the request and respect my tutor.</label>
								<div className="flex justify-end py-2">
									<input id = "submit" className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out" type="submit" name = "submit" required></input>
								</div>
							</div>
						</form>
					);
				}
				else {
					return(
						<div className="mt-1 text-sm leading-5 text-gray-500">Select a date to get started</div>
					)
				}
			}
			if (ucontents.tutor == true) {
				return(
					<div className="mt-1 text-sm leading-5 text-gray-500">Please register as a student to create tutoring requests</div>
				)
			}
		}
		return(<div className="mt-1 text-sm leading-5 text-gray-500">Please sign up as a tutor or student</div>);
	}
	handleSubmit = (event) => {
	  event.preventDefault()
	  let data = {
		  date: event.target.date.value,
		  time: event.target.time.value,
		  subject: event.target.subject.value,
		  unit: event.target.unit.value,
		  id: window.user.email
	  };
	  console.log(data);
	  console.log(window.user.email)
	  SessDB.create(data).then(() => {
		  console.log("Created User with items" + data + "successfully!");
		  window.location.reload();
		  }).catch((error) => {
		  console.log(error);
	  });
	}
	
	handleFillSubmit = (event) => {
	  event.preventDefault()
	  let data = this.state.contents[0]
	  data.tutor = window.user.email
	  console.log(data);
	  console.log(window.user.email)
	  console.log(this.state.sess)
	  SessDB.update(this.state.sess, data).then(() => {
		  console.log("Updated User with items" + data + "successfully!");
		  window.location.reload();
	  }).catch((error) => {
		  console.log(error);
	  });
	}
	  //window.location.reload();
	displaySessionRequests = (day) => {
		let dateContext = Object.assign({}, this.state.dateContext);
		dateContext = moment(dateContext).set("date", day);
		const Sess = SessDB.getSess(dateContext.format('MM/DD/YYYY'), "date");
		const Contents = SessDB.getContents(dateContext.format('MM/DD/YYYY'), "date");
		let sub = [];
		for (var z = 0 ; z<Sess.length ; z++) {
			if (z==0) {
				sub.push(<option value="">Select</option>);
			}
			sub.push(<option value={Sess[z]}>{Contents[z].subject}</option>);
		}
		if (Sess.length>0) {
			console.log(Contents)
			let main = [<select name="chooseSession" onChange={this.fillRequest}>{sub}</select>]
			return(main);
		}
		else {
			return null;
		}
	}
	
	fillRequest = (e) => {
		console.log(e)
		if (e !== undefined) {
			if (e.target !== undefined) {
				console.log("Change Detected", e.target.value)
				let Content = SessDB.getFromRef(e.target.value)
				console.log(Content)
				if (Content !== undefined) {
					this.setState({contents: Content, sess: e.target.value})
				}
			}
		}
		else {
			return null;
		}
	}
	
	bottomSide = () => {
		console.log(this.state.contents)
		if (this.state.contents !== undefined) {
			if (this.state.contents.length !== 0) {
				let contents = this.state.contents[0]
				if(contents.tutor == undefined) {
					return(
					<div>
						<form onSubmit={this.handleFillSubmit}>
							<div className="grid grid-cols-3 gap-6 text-left py-4">
								<div className="col-span-1">
								<label for="date" className="block text-sm font-medium leading-5 text-gray-700">Date:</label>
								 <input readOnly id="date" name="date" value={contents.date} className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
								</div>
								<div className="col-span-2">
									<label name="subject" for="subject" className="block text-sm font-medium leading-5 text-gray-700">Subject: </label>
									<input readOnly value={contents.subject} name="subject" id="subject" required className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
								</div>
								<div className="col-span-1">
									<label name="time" for="time" className="block text-sm font-medium leading-5 text-gray-700">Time of Session: </label>
									<input readOnly value={contents.time} type="time" id="time" name="time" required className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
								</div>
								<div className="col-span-2">
									<label name="unit" for="unit" className="block text-sm font-medium leading-5 text-gray-700">Topics that student requires help in: </label>
									<input readOnly value={contents.unit} name="unit" id="unit" required className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"></input>
								</div>
							</div>
								<div className="border-t border-gray-200">
									<input id = "schecked" type="checkbox" name = "schecked" required></input>
									<label for="schecked" name="schecked" className="p-1">I understand that it is my responsibility to prepare material as a tutor for the session and teach my students on the date requested above. </label>
									<div className="flex justify-end py-2">
										<input id = "submit" className="ml-3 inline-flex rounded-md shadow-sm inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-indigo active:bg-blue-700 transition duration-150 ease-in-out" type="submit" name = "submit" required></input>
									</div>
								</div>
							</form>
					</div>
					);
				}
				else {
					return(
					<div>A tutor has already filled the session request.</div> 
					)
				}
			}
			else {
				return(
				<div></div>
				)
			}
		}
		else {
			return(
			<div></div>
			);
		}
	}
    render() {
        // Map the weekdays i.e Sun, Mon, Tue etc as <td>
        let weekdays = this.weekdaysShort.map((day) => {
            return (
                <td key={day} className="text-white px-6">{day}</td>
            )
        });

        let blanks = [];
        for (let i = 0; i < this.firstDayOfMonth(); i++) {
            blanks.push(<td key={i * 80} className="emptySlot">
                {""}
                </td>
            );
        }

        console.log("blanks: ", blanks);

        let daysInMonth = [];
        for (let d = 1; d <= this.daysInMonth(); d++) {
            let className = (d == this.currentDay() ? "day current-day": "day");
            let selectedClass = (d == this.state.selectedDay ? " selected-day " : "")
            daysInMonth.push(
                <td key={d} className="text-gray-600 hover:bg-gray-100 relative text-center justify-center border-blue-600 border-4 bg-white" >
                    <div tabindex = "0" className="focus:bg-gray-100 hover:bg-gray-100 p-2 py-6 bg-white" onClick={(e)=>{this.onDayClick(e, d)}}>{d}
					<div className="text-xs ">{this.displaySessionRequests(d)}</div>
					</div>
                </td>
            );
        }


        console.log("days: ", daysInMonth);

        var totalSlots = [...blanks, ...daysInMonth];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if ((i % 7) !== 0) {
                cells.push(row);
            } else {
                let insertRow = cells.slice();
                rows.push(insertRow);
                cells = [];
                cells.push(row);
            }
            if (i === totalSlots.length - 1) {
                let insertRow = cells.slice();
                rows.push(insertRow);
            }
        });

        let trElems = rows.map((d, i) => {
            return (
                <tr key={i*100} className="">
                    {d}
                </tr>
            );
        })

        return (
            <div className="flex grid gap-4 grid-cols-2">
				<div className="bg-white rounded-xl col-span-1 text-center p-4 grid grid-rows-2">
					<div className="row-span-1 p-4">
						<h3 className="text-lg font-medium leading-6 text-gray-900 pb-2">Request a Tutor Session</h3>
						<this.showSideBar />
					</div>
					<div className="row-span-1 border-t-2 border-gray-200">
						<div className="text-lg font-medium leading-6 text-gray-900 p-4">Tutor a Student</div>
						<this.bottomSide />
					</div>
				</div>
				<div className="bg-blue-600 rounded-xl p-4 col-span-1 flex justify-center">
                <table className="bg-blue-600 border-4 border-blue-600 table-auto">
                    <thead>
                        <tr className="text-yellow-300">
                            <td colSpan="5" className="p-4">
                                <this.MonthNav />
                                {" "}
                                <this.YearNav />
                            </td>
                            <td colSpan="2" className="nav-month">
                                <i className="bg-yellow-300"
                                    onClick={(e)=> {this.prevMonth()}}>
                                </i>
                                <i className="prev fa fa-fw fa-chevron-right"
                                    onClick={(e)=> {this.nextMonth()}}>
                                </i>

                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {weekdays}
                        </tr>
                        {trElems}
                    </tbody>
                </table>
				</div>

            </div>

        );
    }
}