import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import './App.css';
import logo from './logo.svg';


import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";


import Home from "views/member/Home.js";
import Tutor from "views/member/Tutor.js";
import Student from "views/member/Student.js";
import Calendar from "views/member/Calendar.js";
import Member from "views/member/Member.js";
import Plus from "views/member/Plus.js";
import Vote from "views/member/Vote.js";
import Homepage from "views/Homepage.js"

import Form from "test/formview.js"
import FormAdmin from "test/formadmin.js"
import Videoplayer from "test/videoplayer.js"
import Menu from "test/Menu.js"

import Hamlet from "views/nonmember/Hamlet";
import firebase from "Firebase.js"
function App(){
	const [signedIn, setSignedIn] = useState(false)
	return(
	<>
	{signedIn ?
	<>
		<Sidebar />
		<div className="relative md:ml-64 bg-gray-200">
			<AdminNavbar setSignedIn={setSignedIn}/>
			<div className="relative bg-blue-800 md:pt-10 pb-10 pt-0">
				<div className="px-4 md:px-10 mx-auto w-full">
					<div className="flex flex-wrap">
					</div>
				</div>
			</div>
			<div className="px-4 md:px-10 mx-auto w-full md:pt-10">
				  <Switch>
					<Route path="/home" exact component={Home} />
					<Route path="/tutor" exact component={Tutor} />
					<Route path="/student" exact component={Student} />
					<Route path="/calendar" exact component={Calendar} />
					<Route path="/form" exact component={Form} />
					<Route path="/plus" exact component={Plus} />
					<Route path="/videoplayer" exact component={Videoplayer} />
					<Route path="/formadmin" exact component={FormAdmin} />
					<Route path="/member" exact component={Member} />
					<Route path="/vote" exact component={Vote} />
					<Route path="/menu" exact component={Menu} />
					<Route path="/hamlet" exact component={Hamlet} />
					<Redirect from="*" to="/home" />
				  </Switch>
				  <FooterAdmin />
			</div>
		</div>
	</>
	:
	<>
	{window.location.pathname=="/hamlet"?
	<>
		<Hamlet />
	</>
	:
	<>
		<Homepage signedIn={signedIn} setSignedIn={setSignedIn} />
	</>
	}
	
	</>
	}
	</>
	);
}

export default App;
