import React from "react";

// components

export default function Home() {
  return (
    <>
      <div className="flex flex-wrap">
		<div className="w-full px-4">
			<div className="w-full mx-auto bg-blue-600 rounded-xl shadow-md overflow-hidden flex justify-center md:pt-2 px-4">
				<div class="p-2">
				<div class="tracking-wide text-sm text-yellow-300 text-5xl font-thin text-center pb-4">
					Home
				</div>
				<p class="text-white font-thin text-center">
					Please read the following handbook for guidelines on the tutoring system. Register as a member and  if interested in tutoring, select the tab on the left that applies to you. You can be both a student and tutor if you so choose.
				</p>
				</div>
			</div>
			<div className="flex justify-center pt-4">
			<iframe src={require("assets/files/handbook.pdf").default} width="90%" height="550 px"></iframe>
			</div>
		</div>
	  </div>
    </>
  );
}