/*eslint-disable*/
import React, { useEffect } from "react";
import firebase, { signInWithGoogle } from "Firebase.js"
import HomeNavbar from "components/Navbars/HomeNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Homepage(props) {
	useEffect(() => {
    console.log("Signed in: " + props.signedIn)
  }, [props.signedIn])

  // Attach an event listener that listens for changes in auth state and updates the signedIn bool
  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      props.setSignedIn(!!user)
	  window.user = user
    })
  }, [])
  return (
    <>
	<div className="bg-cover bg-center bg-soar" style={{ backgroundImage: `url(${require('assets/imgs/Loginbg.jpg')})` }}>
      <HomeNavbar fixed />
        <section className="header relative items-center flex h-screen justify-center">
          <div className="rounded-lg bg-white shadow-lg p-5 object-center center">
                <h2 className="font-semibold text-4xl text-gray-700 text-center">
                  SOAR Portal
                </h2>
				<hr className="my-4 md:min-w-full" />
                <p className="mt-4 text-lg leading-relaxed text-gray-400 justify-center">
                  {/* implement random phrase here */}
                  Please sign in with your school email
                </p>
                <div className="mt-12">
                  Sign in with
                  <button
					onClick={signInWithGoogle}
                    className="mx-4 bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs ease-linear transition-all duration-150"
                    type="button">
					<img alt="..." className="w-5 mr-1" src={require("assets/imgs/google.svg").default} /> 
                    Google
                  </button>
            </div>
          </div>
        </section>
	  </div>
      <Footer />
    </>
  );
}